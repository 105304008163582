@import '../../../../../src/Components/common/Modal/ModalExports.scss';

.newMenuModalOverlay {
    @include overlay;

    background-color: #ffffff;
}

.newMenuClose {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;

    svg {
        rect {
            fill: #176061;
        }

        &:hover {
            rect {
                fill: #F36A26;
            }
        }
    }
}

   

.menuLinksWrapper {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 50px 20px;
    font-family: 'Poppins', sans-serif;
}

.menuLink {
    color: #176061;
    padding: 15px 25px;
    border-bottom: 1px solid #17606129;
    font-size: 16px;
    line-height: 16px;

    &:last-child {
        border-bottom: none
    }

    &:hover {
        color: #F36A26;
    }
}

.boldLink {
    font-weight: 700;
}

.menuBtnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    a {
        min-width: 160px;
        height: 47px;
        border-radius: 10px;
        font-size: 13px;
    }

    @media (max-width: 460px) {
        min-width: 144px;
        height: 42px;
        font-size: 13px;
    }
}